<template>
  <v-card v-if="condition != null && hasUserRight('manageCondition', condition.providerUid, condition.brands)" flat
    class="px-4">
    <v-card-text>
      <v-row>
        <!-- YARETO Teil -->
        <v-col v-if="useConditionKeyVariable()" cols="12" lg="1" md="4" sm="4" xs="2">
          <v-radio-group v-model="condition.conditionKeyFix" column
            @change="setConditionKeyFix('conditionKeyFix', $event)">
            <v-radio :label="$t('condAdmin.condition.conditionKeyID.labelFix')" :value="true" />
            <v-radio :label="$t('condAdmin.condition.conditionKeyID.labelVariabel')" :value="false" />
          </v-radio-group>
        </v-col>
        <v-col v-if="useConditionKeyVariable()" cols="12" lg="3" md="4" sm="4" xs="4">
          <v-text-field v-if="condition.conditionKeyFix === true" @change="setDirty"
            :label="$t('condAdmin.condition.conditionKey') + $t('base.mandatory')" v-model.trim="condition.conditionKey"
            :rules="[rules.required]" />
          <v-text-field v-if="condition.conditionKeyFix === false" :label="$t('condAdmin.condition.conditionKey')"
            readonly :value="$t('condAdmin.condition.conditionKeyID.variabelInput')" class="subsidyFieldDisabled" />
        </v-col>
        <!-- YARETO Teil Ende -->
        <!-- SONST -->
        <v-col v-if="!useConditionKeyVariable()" cols="12" lg="4" md="4" sm="6" xs="6">
          <v-text-field @change="setDirty" :label="$t('condAdmin.condition.conditionKey') + $t('base.mandatory')"
            v-model.trim="condition.conditionKey" :rules="[rules.required]" />
        </v-col>

        <!-- SONST -->
        <v-col cols="12" lg="4" md="4" sm="6" xs="6">
          <v-text-field @change="setDirty" :label="$t('condAdmin.condition.name') + $t('base.mandatory')"
            v-model.trim="condition.name" :rules="[rules.required]" />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6" xs="6" class="mt-2">
          <v-row no-gutters class="justify-end">
            <v-btn @click.native="$router.back()" class="mr-4 prio2">
              {{ $t('base.cancel') }}
            </v-btn>
            <v-btn :disabled="!isDirty" @click.native="save()" color="secondary" class="mr-4">
              {{ $t('condAdmin.actions.save') }}
            </v-btn>
            <!--<v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :disabled="!enableAction('save')"
                  @click="save()"
                >{{ capitalizeString('condAdmin.actions.save') }}</v-list-item>
                <v-list-item
                  :disabled="!enableAction('setCheck')"
                  @click="setCheckState(condition)"
                >{{ capitalizeString('condAdmin.actions.setCheck') }}</v-list-item>
              </v-list>
            </v-menu>-->
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="6" xs="6">
          <v-select :value="condition.calcType" :items="calcTypeList" item-text="text" item-value="calcType"
            :label="$t('condAdmin.condition.calcType')" @change="setCalcType"></v-select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6" xs="6">
          <v-select v-model="condition.providerUid" :items="$store.state.condAdmin.providerList" item-text="name"
            item-value="uid" :label="$t('condAdmin.condition.provider') + $t('base.mandatory')" @change="setProvider"
            :rules="[rules.required]"></v-select>
        </v-col>
      </v-row>
      <v-row v-if="useInterestType() === true">
        <v-col cols="12" lg="4" md="4" sm="6" xs="6">
          <v-select :value="condition.additionalParams.interestType.indexName" :items="interestTypeList" item-text="name"
            item-value="indexName" :label="$t('condAdmin.condition.interestType')" @change="setInterestType"></v-select>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          <div class="caption">{{ $t('condAdmin.version') }}</div>
          <div class="">{{ condition.version }}</div>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          <div class="caption">{{ $t('base.state') }} </div>
          <v-chip :class="stateClass" class="">
            {{ stateText }}
          </v-chip>
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          {{ $t('condAdmin.validFrom') + ' ' }}
          <DatePicker :value="condition.validFrom" :isBegin="true" @change="setProperty('validFrom', $event)" />
        </v-col>
        <v-col cols="12" lg="2" md="2" sm="3" xs="3">
          {{ $t('condAdmin.validTo') + ' ' }}
          <DatePicker :value="condition.validTo" :isEnd="true" @change="setProperty('validTo', $event)" />
          <!--<span class="text-h6">{{ formatEndDate(condition.validTo) }}</span>-->
        </v-col>
      </v-row>
      <div v-if="condition.calcType != null">

        <v-row>
        </v-row>
        <!-- #### Produkt-Ein-/Ausschlüsse #### -->
        <v-row class="grey lighten-5">
          <v-col class="pb-0 text-h6">
            <span>{{ $t('condAdmin.condition.products') }}</span>
            <!--<v-btn
              class="ml-8"
              icon
              color="secondary"
              @click="addProductAssignment"
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>-->
          </v-col>
        </v-row>
        <v-row class="grey lighten-5">
          <v-col>
            <v-data-table :headers="productHeaders" :items="condition.products" fixed-header sort-by="brand"
              hide-default-footer item-key="id" class="grey lighten-5 noLines" :items-per-page="-1">
              <template v-for="header in productHeaders.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }">
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.isInclude`]="{ item }">
                <v-select v-model="item.isInclude" :items="inExcludeModes" item-text="text" item-value="value"
                  @change="setDirty" hide-details dense>
                  <template v-slot:selection="{ item }">
                    <v-chip :class="item.class">
                      {{ item.text }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip :class="item.class">
                      {{ item.text }}
                    </v-chip>
                  </template>
                </v-select>
              </template>
              <template v-slot:[`item.productType`]="{ item }">
                <v-select :value="item.productTypeUid" @change="setProductType(item, $event)"
                  :items="[{ uid: null, name: $t('base.all') }].concat($store.state.condAdmin.productTypeList)"
                  item-text="name" item-value="uid" hide-details dense>
                </v-select>
              </template>
              <template v-slot:[`item.subProductType`]="{ item }">
                <v-select :disabled="item.productTypeUid == null" :value="item.subProductTypeUid"
                  @change="setSubProductType(item, $event)"
                  :items="[{ uid: null, name: $t('base.all') }].concat(getSubProductTypeList(item.productTypeUid))"
                  item-text="name" item-value="uid" item-key="uid" :key="item.id" hide-details dense>
                </v-select>
              </template>
              <template v-slot:[`item.brand`]="{ item }">
                <v-select v-model="item.brand" @change="setDirty" :items="brandList" item-text="text" item-value="brand"
                  hide-details dense>
                </v-select>
              </template>
              <template v-slot:[`item.productName`]="{ item }">
                <v-text-field @change="setDirty" v-model="item.productName" clearable hide-details dense />
              </template>
              <template v-slot:[`item.chassis`]="{ item }">
                <v-select v-model="item.chassis" :items="chassisTypes" @change="setDirty" hide-details dense></v-select>
              </template>
              <template v-slot:[`item.driveType`]="{ item }">
                <v-select v-model="item.driveType" :items="driveTypes" @change="setDirty" hide-details dense></v-select>
              </template>
              <template v-slot:[`item.menu`]="{ item }">
                <v-btn @click="copyProductAssignment(item)" icon color="secondary">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn @click="removeProductAssignment(item)" icon color="secondary"
                  :disabled="condition.products.length < 2">
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <v-btn class="mt-2" icon color="secondary" @click="addProductAssignment">
              <v-icon large>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- #### Betragsbänder #### -->
        <v-row>
          <v-col class="mb-4">
            <span class="py-2 text-h6">{{ $t('condAdmin.condition.interestLines') }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="conditionTable">
          <table class="text-center v-data-table" cellspacing=0 cellpadding=0>
            <tr>
              <th class="firstRow first fixCol"></th>
              <th class="pa-1 firstRow" v-for="(il) in condition.interestLines" :key="il.index">
                <v-row no-gutters class="justify-end">
                  <v-btn icon color="secondary" @click="copyInterestLine(il.index)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn icon color="secondary" @click="removeInterestLine(il.index)"
                    :disabled="condition.interestLines.length < 2">
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </v-row>
              </th>
              <td class="noBorder text-left smallRow">
                <v-btn icon color="secondary" @click="addInterestLine()">
                  <v-icon>mdi-table-column-plus-after</v-icon>
                </v-btn>
              </td>
              <th class="first" v-if="residualValues != null && condition.calcType !== 'CC'">
                <v-row no-gutters class="justify-end align-center">
                  <span class="text-cemter" style="width: calc(100% - 40px);">{{ isLeasing(condition.calcType) ?
                    $t('condAdmin.condition.residualValue') : $t('condAdmin.condition.finalPayment') }}</span>
                  <v-btn icon color="secondary" @click="removeResidualValues()">
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </v-row>
              </th>
            </tr>
            <tr class="flex-nowrap">
              <td class="text-center first px-2 pt-3 fixCol creditCol">{{ $t('condAdmin.condition.credit') }}</td>
              <td class="px-4 pb-2" v-for="(il) in condition.interestLines" :key="il.index"
                :class="il.isOk ? '' : 'error'">
                <v-row no-gutters class="align-center flex-nowrap">
                  <EditField @change="setMinCredit(il.index, $event)" @blur="sortInterestLines(condition)"
                    :value="il.minCredit" :label="$t('base.from')" clearable hideDetails :suffix="currency" :digits="2"
                    type="ufloat" class="creditField" />

                  <v-chip v-if="useInterestType() === true && condition.additionalParams.interestType.name === 'Aktion'"
                    @click="setInterestTypeAtttribute(il.index)" :outlined="!getInterestTypeAttribute(il.index)"
                    class='mt-4'
                    :class="getInterestTypeClass(condition.additionalParams.interestType, true, getInterestTypeAttribute(il.index))">
                    {{ interestTypeText }}
                  </v-chip>
                </v-row>

                <EditField @change="setMaxCredit(il.index, $event)" @blur="sortInterestLines(condition)"
                  :value="il.maxCredit" :label="$t('base.to')" clearable hideDetails :suffix="currency" :digits="2"
                  type="ufloat" class="creditField" />

              </td>

              <td class="noBorder smallRow"></td>
              <td class="px-4 first pb-2" v-if="residualValues != null && condition.calcType !== 'CC'">
                <EditField @change="setDirty" v-model="condition.additionalParams.rvMaxRaise"
                  :label="$t('condAdmin.condition.rvMaxRaise')" clearable hideDetails suffix="%-Pkt" type="ufloat"
                  class="creditField" />
                <EditField @change="setDirty" v-model="condition.additionalParams.rvMaxLowering"
                  :label="$t('condAdmin.condition.rvMaxLowering')" clearable hideDetails suffix="%-Pkt" type="ufloat"
                  class="creditField" />
              </td>
            </tr>
            <tr>
              <th class="fixCol first greyRow fixCol pt-3">{{ $t('condAdmin.condition.duration') }}</th>
              <th :colspan="condition.interestLines.length" class="py-2 greyRow">
                {{ condition.interestMode === 'factors' ? $t('condAdmin.condition.leasingFactors') :
                  $t('condAdmin.condition.interestRate') }}
              </th>
              <td class="noBorder smallRow text-left" v-if="residualValues == null && condition.calcType !== 'CC'">
                <v-btn icon color="secondary" @click="createResidualValues()"
                  v-tooltip="$tooltip($t('condAdmin.conditionEditor.ttCreateResidualValues'))">
                  <v-icon>mdi-chart-timeline-variant</v-icon>
                </v-btn>
              </td>
              <td v-else class="noBorder smallRow"></td>
              <th class="first greyRow" v-if="residualValues != null && condition.calcType !== 'CC'">
              </th>
            </tr>
            <tr v-for="(dur) in durationList" :key="dur">
              <td class="fixCol first pt-3" :class="dur === maxDuration ? 'lastRow' : ''">{{ dur }}</td>
              <td v-for="(il) in condition.interestLines" :key="il.index" class="px-4"
                :class="dur === maxDuration ? 'lastRow' : ''" style="min-width: 150px;">
                <v-row v-if="condition.interestMode === 'factors'" class="flex-nowrap">
                  <v-col>
                    <EditField :label="$t('condAdmin.condition.plusFactor')"
                      @change="setAttributeValue('+', il.index, dur, $event)"
                      :value="getAttributeValue('+', il.index, dur)" :placeholder="$t('base.value')" clearable hideDetails
                      type="ufloat" class="mr-2" />
                  </v-col>
                  <v-col>
                    <EditField :label="$t('condAdmin.condition.minusFactor')"
                      @change="setAttributeValue('-', il.index, dur, $event)"
                      :value="getAttributeValue('-', il.index, dur)" :placeholder="$t('base.value')" clearable hideDetails
                      type="ufloat" />
                  </v-col>
                  <v-icon
                    v-if="dur !== maxDuration && (getAttributeValue('+', il.index, dur) != null || getAttributeValue('-', il.index, dur) != null)"
                    @click="copyAttributeValue(il.index, dur)" class="secondary--text mt-4">
                    mdi-arrow-down
                  </v-icon>
                  <v-chip
                    v-if="useInterestType() === true && dur !== maxDuration && (getAttributeValue('+', il.index, dur) != null || getAttributeValue('-', il.index, dur) != null) && condition.additionalParams.interestType.name === 'Aktion'"
                    @click="setInterestTypeAtttribute(il.index, dur)" :outlined="!getInterestTypeAttribute(il.index, dur)"
                    class="mt-0"
                    :class="getInterestTypeClass(condition.additionalParams.interestType, true, getInterestTypeAttribute(il.index, dur))">
                    {{ interestTypeText }}
                  </v-chip>
                  <div v-else class="secondary--text mt-4 px-3">
                  </div>
                </v-row>
                <v-row v-else no-gutters class="align-center flex-nowrap">

                  <EditField @change="setAttributeValue('%', il.index, dur, $event)"
                    :value="getAttributeValue('%', il.index, dur)" :placeholder="$t('base.value')" clearable hideDetails
                    suffix="%" type="float" class="interestField" />
                  <v-icon v-if="dur !== maxDuration && getAttributeValue('%', il.index, dur) != null"
                    @click="copyAttributeValue(il.index, dur)" class="clickable secondary--text"
                    v-tooltip="$tooltip($t('condAdmin.conditionEditor.ttCopyInterestAttribute'))">mdi-arrow-down</v-icon>
                  <v-chip
                    v-if="useInterestType() === true && dur !== maxDuration && getAttributeValue('%', il.index, dur) != null && condition.additionalParams.interestType.name === 'Aktion'"
                    @click="setInterestTypeAtttribute(il.index, dur)" :outlined="!getInterestTypeAttribute(il.index, dur)"
                    class="mt-0"
                    :class="getInterestTypeClass(condition.additionalParams.interestType, true, getInterestTypeAttribute(il.index, dur))">
                    {{ interestTypeText }}
                  </v-chip>

                </v-row>
              </td>
              <td class="noBorder smallRow"></td>
              <td v-if="residualValues != null && condition.calcType !== 'CC'" class="first px-4"
                :class="dur === maxDuration ? 'lastRow' : ''">
                <v-row class="pb-2">
                  <v-col>
                    <EditField @change="setResidualValue(dur, $event)" :value="getResidualValue(dur)"
                      :placeholder="$t('base.value')" clearable hideDetails suffix="%" type="float"
                      class="interestField" />
                  </v-col>
                </v-row>
              </td>
            </tr>
          </table>
        </v-row>
        <!-- #### Provisionen #### -->
        <CommissionEditor v-if="$store.state.condAdmin.orgSettings.useInterestScale === true"
          :customComponents="customComponents"
          :interestLineCounter="interestLineCounter"
          :interestScaleList="interestScaleList"
          :durationList="durationList"
          :attributes="condition.attributes"
          :interestLines="condition.interestLines"
          :commissionParams="condition.commissionParams"
          :objectKey="condition.clientKey + '/' + condition.uid + '_v' + condition.version"
          @setDirty="setDirty"
          @updateConditionAttributes="updateConditionAttributes($event)"
          ref="CommissionEditor" />
        <!-- #### Konditionsschlüssel #### -->

        <component v-show="customComponents.ConditionKeyID != null && condition.conditionKeyFix === false"
          v-bind:is="customComponents.ConditionKeyID"
          ref="customConditionId"
          :attributes="condition.attributes"
          :interestLines="condition.interestLines"
          :products="condition.products" :durationList="durationList"
          :conditionKeyParams="condition.conditionKeyParams === null ? {} : JSON.parse(JSON.stringify(condition.conditionKeyParams))"
          :readonly="false"
          :clearable="false"
          @setDirty="setDirty">
        </component>

        <!-- #### Konditions-Einstellungen #### -->

        <v-row>
          <v-col>
            <span class="pt-2 text-h6">{{ $t('base.settings') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <v-select v-model="condition.interestMode" :items="interestModeList" item-text="text" item-value="value"
              :label="$t('condAdmin.condition.interestMode.label')" @change="setDirty"></v-select>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <EditField @change="setDirty" v-model="condition.additionalParams.commissionRetention"
              :label="$t('condAdmin.condition.commissionRetention')" clearable :suffix="currency" type="ufloat" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            {{ $t('condAdmin.condition.add.billingPeriod') + ' ' }}
            <DatePicker :value="condition.additionalParams.billingPeriod" :useTime="false"
              @change="setProperty('additionalParams.billingPeriod', $event)" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <v-row>
              <v-col>
                <EditField @change="setDirty" v-model="condition.minDepositPercent"
                  :label="$t('condAdmin.condition.minDeposit')" clearable suffix="%"
                  :rules="[rules.depositRange(condition), rules.max100]" type="ufloat" />
              </v-col>
              <v-col>
                <EditField @change="setDirty" v-model="condition.maxDepositPercent"
                  :label="$t('condAdmin.condition.maxDeposit')" clearable suffix="%"
                  :rules="[rules.depositRange(condition), rules.max100]" type="ufloat" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <EditField @change="setDirty" v-model="condition.minRate" :label="$t('condAdmin.condition.minRate')" clearable
              :suffix="currency" type="ufloat" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <v-row>
              <v-col>
                <EditField @change="setDirty" :suffix="$t('base.months')" :label="$t('condAdmin.condition.minVehicleAge')"
                  v-model="condition.minVehicleAge" :rules="[rules.vehicleAgeRange(condition)]"
                  :placeholder="$t('base.NULL')" clearable type="uint" />
              </v-col>
              <v-col>
                <EditField @change="setDirty" :suffix="$t('base.months')" :label="$t('condAdmin.condition.maxVehicleAge')"
                  v-model="condition.maxVehicleAge" :rules="[rules.vehicleAgeRange(condition)]"
                  :placeholder="$t('base.NULL')" clearable type="uint" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <v-select v-model="condition.maxMileage" :items="mileageList" :label="$t('condAdmin.condition.maxMileage')"
              @change="setDirty" suffix="km" clearable></v-select>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <v-row v-if="condition.calcType != 'CC' && condition.calcType != 'BC'">
              <v-col>
                <EditField @change="setDirty" :label="$t('condAdmin.condition.add.addKmCharge')"
                  v-model="condition.additionalParams.addKmCharge" :suffix="currencyCent" clearable type="float" />
              </v-col>
              <v-col>
                <EditField @change="setDirty" :label="$t('condAdmin.condition.add.lowKmCharge')"
                  v-model="condition.additionalParams.lowKmCharge" :suffix="currencyCent" clearable type="float" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- #### Händler-Ein-/Ausschlüsse #### -->
        <v-row class="grey lighten-5">
          <v-col class="text-h6">
            <span>{{ $t('condAdmin.condition.dealers') }}</span>
          </v-col>
        </v-row>
        <v-row class="grey lighten-5" v-if="$store.state.condAdmin.orgSettings.useInternalDealerList !== false">
          <v-col>
            <v-data-table :headers="dealerHeaders" :items="condition.dealers" fixed-header sort-by="dealerId"
              hide-default-footer class="noLines">
              <template v-for="header in dealerHeaders.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }">
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.isInclude`]="{ item }">
                <v-select v-model="item.isInclude" :items="inExcludeModes" item-text="text" item-value="value"
                  @change="setDirty" hide-details dense>
                  <template v-slot:selection="{ item }">
                    <v-chip :class="item.class">
                      {{ item.text }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip :class="item.class">
                      {{ item.text }}
                    </v-chip>
                  </template>
                </v-select>
              </template>
              <template v-slot:[`item.dealerKey`]="{ item }">
                <v-select :key="item.id" v-model="item.dealerKey"
                  :items="[{ id: null, name: null, dealerKey: null, city: null }].concat($store.state.condAdmin.dealerList)"
                  item-text="dealerKey" item-value="id"
                  @input="setDealerAssignment(item, $event)"
                  hide-details dense>
                  <template v-slot:selection="{ item }">
                    {{ item.dealerKey || $t('base.all') }}
                  </template>
                  <template v-slot:item="{ item }">
                    <v-row>
                      <v-col>{{ item.dealerKey || $t('base.all') }}</v-col>
                      <v-col>{{ item.name }}</v-col>
                    </v-row>
                  </template>
                </v-select>
              </template>
              <template v-slot:[`item.menu`]="{ item }">
                <v-row no-gutters>
                  <v-btn @click="copyDealerAssignment(item)" icon color="secondary">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn @click="removeDealerAssignment(item)" icon color="secondary"
                    :disabled="condition.dealers.length < 2">
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-data-table>
            <v-btn class="mt-2" icon color="secondary" @click="addDealerAssignment">
              <v-icon large>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <component class="grey lighten-5 pb-4" v-bind:is="customComponents.ConditionDealerSettings"
          ref="customConditionDealerSettings" :params="condition.additionalParams || {}" @setDirty="setDirty"
          v-show="customComponents.ConditionDealerSettings != null"></component>
        <!-- #### additional Data #### -->
        <v-row>
          <v-col class="pb-0 text-h6">
            <span>{{ $t('condAdmin.condition.additionalData') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6">
            <v-text-field @change="setDirty" :label="$t('condAdmin.condition.add.internalContact')"
              v-model.trim="condition.additionalParams.internalContact" />
            <v-text-field @change="setDirty" :label="$t('condAdmin.condition.add.externalContact')"
              v-model.trim="condition.additionalParams.externalContact" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6" class="pt-9">
            <v-textarea :label="getAdditionalInformationLabel" outlined @change="setAdditionalInformationText($event)"
              :value="getAdditionalInformationText" />
            <v-textarea v-if="useInterestType() === true && condition.additionalParams.interestType.name === 'Bonität'"
              :label="$t('condAdmin.condition.add.creditRatingAdditionalInformation')" outlined @change="setDirty"
              v-model.trim="condition.additionalParams.creditRatingAdditionalInformation" />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6" xs="6" class="pt-9">
            <v-textarea :label="$t('condAdmin.condition.add.comment')" outlined @change="setDirty"
              v-model.trim="condition.additionalParams.comment" />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <SaveOnLeaveDlg ref="leaveDialog" />
  </v-card>
</template>

<script lang="js">
import { setProperty } from '@/base/js/ObjectHelper';
import { refreshProductTypeList, refreshProductList, getCalcTypeDurations, removeLock } from '../js/condAdminHelper.js';
import condAdminConditionMixin from '../js/condAdminConditionMixin.js';
import CommissionEditor from './CommissionEditor';
import EditField from '@/common/EditField';
import DatePicker from '@/common/DatePicker';
import SaveOnLeaveDlg from '@/common/SaveOnLeaveDlg';

export default {
  name: 'ConditionEditor',
  components: {
    CommissionEditor,
    DatePicker,
    EditField,
    SaveOnLeaveDlg
  },
  mixins: [condAdminConditionMixin],
  data () {
    return {
      condition: null,
      isDirty: false,
      customComponents: {},
      residualValues: null,
      locked: true,
      interestLineCounter: 0,
      dealerHeaders: [
        { width: '170px', text: this.$t('condAdmin.isInclude.label'), value: 'isInclude' },
        { width: '400px', text: this.$t('condAdmin.dealer.dealerKey'), value: 'dealerKey', formatter: this.replaceAsAll },
        { text: this.$t('base.contact.name'), value: 'name', formatter: this.formatValue },
        { text: this.$t('base.contact.city'), value: 'city', formatter: this.formatValue },
        { width: '90px', text: null, value: 'menu', sortable: false }
      ],
      allInterestTypeSet: []
    };
  },
  computed: {
    stateClass () {
      return this.getStateClass(this.condition);
    },
    stateText () {
      return this.getStateText(this.condition);
    },
    calcTypeList () {
      return this.$store.state.condAdmin.calcTypeList.filter((i) => i.isActive === 1 || i.isActive === true);
    },
    interestScaleList () {
      return this.getCalcTypeInterestScale(this.condition.calcType);
    },
    interestModeList () {
      if (this.isFinancing(this.condition.calcType)) {
        return this.interestModes.filter((i) => i.financing === true);
      }
      return this.interestModes.filter((i) => i.leasing === true);
    },
    getAdditionalInformationLabel () {
      let infoLabel = this.$t('condAdmin.condition.add.saleInfo');
      if (this.condition.additionalParams.interestType != null) {
        switch (this.condition.additionalParams.interestType.name) {
          case 'Aktion':
            infoLabel = this.$t('condAdmin.condition.add.campaignInformation');
            break;
          case 'Bonität':
            infoLabel = this.$t('condAdmin.condition.add.creditRatingInformation');
            break;
        }
      }
      return infoLabel;
    },
    getAdditionalInformationText () {
      let infoText = this.condition.additionalParams.saleInfo;
      if (this.condition.additionalParams.interestType != null) {
        switch (this.condition.additionalParams.interestType.name) {
          case 'Aktion':
            infoText = this.condition.additionalParams.campaignInfo;
            break;
          case 'Bonität':
            infoText = this.condition.additionalParams.creditRatingInfo;
            break;
        }
      }
      return infoText;
    },

    productHeaders () {
      let ph = [
        { width: '170px', text: this.$t('condAdmin.isInclude.label'), value: 'isInclude' },
        { text: this.$t('condAdmin.product.productType'), value: 'productType', formatter: this.formatValue },
        { width: '150px', text: this.$t('condAdmin.product.subProductType'), value: 'subProductType', formatter: this.replaceAsAll }
      ];

      if (this.hasResidualValuesPart() === true) {
        ph.push({ text: this.$t('condAdmin.product.brand'), value: 'brand', formatter: this.formatValue });
        ph.push({ text: this.$t('condAdmin.product.productName'), value: 'productName', formatter: this.formatValue });
        ph.push({ text: this.$t('condAdmin.product.chassis'), value: 'chassis', formatter: this.formatValue });
        ph.push({ text: this.$t('condAdmin.product.driveType'), value: 'driveType', formatter: this.formatValue });
      }
      ph.push({ width: '90px', text: null, value: 'menu', sortable: false });
      return ph;
    },
    brandList () {
      let l = [];
      for (let p of this.$store.state.condAdmin.productList) {
        if (l.indexOf((e) => e.brand === p.brand.trim()) < 0 && p.brand != null) {
          l.push({ text: p.brand || this.$t('base.all'), brand: p.brand != null ? p.brand.trim() : null });
        }
      }
      return [{ text: this.$t('base.all'), brand: null }].concat(l.sort((a, b) => a.text.toUpperCase() > b.text.toUpperCase()));
    },
    mileageList () {
      let calcType = this.$store.state.condAdmin.calcTypeList.find((i) => i.uid === this.condition.calcTypeUid);
      return calcType.settings.mileages.sort((a, b) => { return a - b; });
    },
    durationList () {
      return getCalcTypeDurations(this.condition.calcType);
    },
    maxDuration () {
      return this.durationList[this.durationList.length - 1];
    },
    interestTypeList () {
      let list = [];
      for (const value of Object.values(this.interestTypes)) {
        list.push({ indexName: value, name: this.$t('condAdmin.interestType.' + [value] + '.name') });
      }
      return list;
    },
    interestTypeText () {
      return this.getInterestTypeText(this.condition.additionalParams.interestType, true);
    }
  },
  async mounted () {
    let requests = [];
    this.getCustomComponents();
    if (this.$store.state.condAdmin.conditionList == null) requests.push(this.refreshConditionList());
    if (this.$store.state.condAdmin.productTypeList == null) requests.push(refreshProductTypeList());
    if (this.hasResidualValuesPart() === true && this.$store.state.condAdmin.productList == null) requests.push(refreshProductList());
    await Promise.all(requests);
    this.init();
  },
  async beforeRouteLeave (to, from, next) {
    if (this.condition != null && this.isDirty) {
      let save = await this.$refs.leaveDialog.open();
      if (save) {
        if (await this.save()) {
          next();
          return false;
        }
      }
    }
    if (this.condition != null && this.locked === true) await removeLock('condition', this.condition.uid, this.condition.version);
    next();
  },
  methods: {
    async init () {
      this.condition = JSON.parse(JSON.stringify(this.$store.state.condAdmin.workingCondition));
      if (this.condition != null) {
        if (this.condition.interestLines != null) {
          this.interestLineCounter = this.condition.interestLines.length;
          if (this.condition.additionalParams.residualValues != null) {
            this.residualValues = this.condition.additionalParams.residualValues;
          }
          if (this.useInterestType()) {
            if (this.condition.additionalParams.interestType.name === 'Aktion') {
              for (let i = 0; i < this.interestLineCounter; i++) {
                this.allInterestTypeSet.push(true);
                for (let duration in this.durationList) {
                  let att = this.condition.attributes.find((a) =>
                    a.minCredit === this.getInterestLineByIndex(i).minCredit &&
                    a.maxCredit === this.getInterestLineByIndex(i).maxCredit &&
                    a.duration === this.durationList[duration] &&
                    a.ilIndex === i &&
                    !a.commissionInterest);
                  if (att != null) {
                    if (!att.interestTypeAttribute) {
                      this.allInterestTypeSet[i] = false;
                    }
                  }
                }
              }
            }
          }
          this.$emit('setPageMode', this.condition.workingMode, this.condition.name);
        }
      } else {
        this.$router.push('/condAdmin/condition');
      }
    },
    // ----------------------------------------------
    setAdditionalInformationText (value) {
      this.condition.additionalParams.campaignInfo = '';
      this.condition.additionalParams.creditRatingInfo = '';
      this.condition.additionalParams.saleInfo = '';
      if (this.condition.additionalParams.interestType != null) {
        switch (this.condition.additionalParams.interestType.name) {
          case 'Aktion':
            this.condition.additionalParams.campaignInfo = value;
            this.condition.additionalParams.creditRatingAdditionalInformation = '';
            break;
          case 'Bonität':
            this.condition.additionalParams.creditRatingInfo = value;
            break;
          case 'Standard':
            this.condition.additionalParams.saleInfo = value;
            this.condition.additionalParams.creditRatingAdditionalInformation = '';
            break;
        }
      } else {
        this.condition.additionalParams.saleInfo = value;
        this.condition.additionalParams.creditRatingAdditionalInformation = '';
      }
    },
    // ----------------------------------------------
    getCustomComponents () {
      if (this.$store.state.condAdmin.orgSettings.useCustomizedComponents === true) {
        let shortname = this.$store.state.condAdmin.customComponents.shortname;
        let components = this.$store.state.condAdmin.customComponents.components.filter((c) => c.startsWith('Condition'));

        for (let c of components) {
          this.customComponents[c] = () => import('./custom/' + shortname + '/' + c + '_' + shortname + '.vue');
        }
      }
    },
    setDirty () {
      if (!this.isDirty) {
        console.log('set Dirty');
        this.isDirty = true;
      }
    },
    updateConditionAttributes (attributes) {
      this.condition.attributes = attributes;
    },
    enableAction (action) {
      switch (action) {
        case 'save': return this.isDirty;
        case 'remove': return this.condition.state === 0 || this.condition.state === 1;
        case 'setCheck': return !this.isDirty;
      }
      return true;
    },
    setProperty (key, value) {
      if (key.includes('.')) setProperty(key, this.condition, value);
      else this.condition[key] = value;
      this.setDirty();
    },

    useConditionKeyVariable () {
      return this.$store.state.condAdmin.orgSettings.useConditionKeyVariable === true;
    },
    useInterestType () {
      return this.$store.state.condAdmin.orgSettings.useInterestType === true;
    },

    /* *******************
    *
    * Base-Settings
    *
    ******************* */
    setCalcType (calcType) {
      let cto = this.$store.state.condAdmin.calcTypeList.find((c) => c.calcType === calcType);
      this.condition.calcTypeUid = cto.uid;
      this.setDirty();

      if (this.condition.calcType == null) {
        this.condition.calcType = calcType;
        this.condition.interestLines = [];
        this.createInterestLine(calcType, this.condition.interestLines, 0, null);
        this.interestLineCounter = 1;
      } else {
        if (calcType === 'CC') {
          this.residualValues = null;
        }
        this.condition.calcType = calcType;
        // set interestMode
        if (this.isFinancing(calcType) && this.condition.interestMode === 'factors') {
          this.condition.interestMode = 'eff';
        }
      }
    },
    setProvider (providerUid) {
      let provider = this.$store.state.condAdmin.providerList.find((p) => p.uid === providerUid);
      this.condition.providerUid = providerUid;
      this.condition.providerName = provider.name;
      this.setDirty();
    },
    setConditionKeyFix (key, value) {
      this.setProperty(key, value);
      if (!value) {
        this.condition.conditionKey = 'variabel';
      } else {
        if (value === true) {
          this.condition.conditionKey = null;
        }
      }
    },
    setInterestType (value) {
      if (this.condition.additionalParams.interestType.indexName !== value) {
        this.condition.additionalParams.interestType.indexName = value;
        this.condition.additionalParams.interestType.name = this.$t('condAdmin.interestType.' + value + '.name');
        this.condition.additionalParams.campaignInfo = '';
        this.condition.additionalParams.creditRatingInfo = '';
        this.condition.additionalParams.saleInfo = '';
        this.condition.additionalParams.creditRatingAdditionalInformation = '';

        for (let i = 0; i < this.interestLineCounter; i++) {
          this.allInterestTypeSet.push(false);
        }
        let atts = this.condition.attributes.filter((a) => !a.commissionInterest);
        for (let att of atts) {
          att.interestTypeAttribute = false;
        }

        this.setDirty();
      }
    },
    /* *******************
    *
    * ProductAssignment
    *
    ******************* */
    setProductType (item, uid) {
      if (item.productTypeUid !== uid) {
        item.productTypeUid = uid;
        item.subProductTypeUid = null;
        this.setDirty();
      }
    },
    setSubProductType (item, uid) {
      if (item.subProductTypeUid !== uid) {
        item.subProductTypeUid = uid;
        this.setDirty();
      }
    },
    addProductAssignment () {
      let item = {
        'isInclude': 1,
        'productTypeUid': null,
        'subProductTypeUid': null,
        'brand': null,
        'productName': null,
        'productKey': null,
        'orderKey': null,
        'chassis': null,
        'driveType': null,
        'serialId': null
      };
      // this.condition.products.push(JSON.parse(JSON.stringify(item)));
      this.condition.products.push(item);
      /*
      this.condition.products.push({
        isInclude: 1,
        productTypeUid: null,
        subProductType: null,
        brand: null,
        productName: null,
        productKey: null,
        orderKey: null,
        chassis: null,
        driveType: null,
        serialId: null
      }); */
      this.setDirty();
    },
    copyProductAssignment (item) {
      this.condition.products.push(JSON.parse(JSON.stringify(item)));
      this.setDirty();
    },
    removeProductAssignment (item) {
      item.delete = true;
      let index = this.condition.products.findIndex((d) => d.delete === true);
      if (index > -1) {
        this.condition.products.splice(index, 1);
      }
      this.setDirty();
    },
    getSubProductTypeList (productTypeUid) {
      // let list = [{ id: null, name: null }];
      let list = [];
      if (productTypeUid == null) {
        for (let pt of this.$store.state.condAdmin.productTypeList) {
          list = list.concat(pt.subProductTypes);
        }
        return list;
      } else {
        let pt = this.$store.state.condAdmin.productTypeList.find((p) => p.uid === productTypeUid);
        return list.concat(pt.subProductTypes);
      }
    },
    /* *******************
    *
    * InterestLines
    *
    ******************* */
    setMinCredit (ilIndex, value) {
      let attributes = this.condition.attributes.filter((a) => a.ilIndex === ilIndex);
      for (let att of attributes) {
        att.minCredit = value;
      }
      this.getInterestLineByIndex(ilIndex).minCredit = value;
      this.setDirty();
    },
    setMaxCredit (ilIndex, value) {
      let attributes = this.condition.attributes.filter((a) => a.ilIndex === ilIndex);
      for (let att of attributes) {
        att.maxCredit = value;
      }
      this.getInterestLineByIndex(ilIndex).maxCredit = value;
      this.setDirty();
    },
    getConditionAttribute (minCredit, maxCredit, duration, ilIndex, doPush = false, interest = null, returnNull = false, interestTypeAtrribute = false) {
      // let att = null;
      // for (let a of this.condition.attributes) {
      //   if (a.minCredit === minCredit &&
      //   a.maxCredit === maxCredit &&
      //   a.duration === duration &&
      //   a.ilIndex === ilIndex &&
      //   ((interest == null && a.commissionInterest !== true) || a.interestRate === interest)) {
      //     return a;
      //   }
      // }

      let att = this.condition.attributes.find((a) =>
        a.minCredit === minCredit &&
        a.maxCredit === maxCredit &&
        a.duration === duration &&
        a.ilIndex === ilIndex &&
        ((interest == null && a.commissionInterest !== true) || a.interestRate === interest)
      );

      if (att == null && returnNull !== true) {
        att = {
          duration: duration,
          minCredit: minCredit,
          maxCredit: maxCredit,
          commission: [],
          commissionInterest: interest != null,
          interestRate: interest,
          plusFactor: null,
          minusFactor: null,
          interestTypeAttribute: interestTypeAtrribute,
          ilIndex: ilIndex
        };
        if (doPush === true) this.condition.attributes.push(att);
      }
      return att;
    },
    getAttributeValue (prop, ilIndex, duration) {
      let att = this.getConditionAttribute(
        this.getInterestLineByIndex(ilIndex).minCredit,
        this.getInterestLineByIndex(ilIndex).maxCredit,
        duration,
        ilIndex,
        false
      );
      switch (prop) {
        case '+': return att.plusFactor;
        case '-': return att.minusFactor;
        default: return att.interestRate;
      }
    },
    // -------------------------------------------------------------
    setAttributeValue (prop, ilIndex, duration, value) {
      let att = this.getConditionAttribute(
        this.getInterestLineByIndex(ilIndex).minCredit,
        this.getInterestLineByIndex(ilIndex).maxCredit,
        duration,
        ilIndex,
        true
      );
      // sichern des gefundenen Atrtributes
      let saveAtt = JSON.parse(JSON.stringify(att));

      switch (prop) {
        case '+':
          att.plusFactor = value;
          if (att.plusFactor == null && att.minusFactor == null) att.delete = true;
          break;
        case '-':
          att.minusFactor = value;
          if (att.plusFactor == null && att.minusFactor == null) att.delete = true;
          break;
        default:
          att.interestRate = value;
          if (att.interestRate == null) att.delete = true;
          break;
      }
      att.commissionInterest = false;
      this.setDirty();
      // Untersuchung, ob Bestzins bereits mit Provision hinterlegt ist
      let doublets = this.condition.attributes.filter((a) =>
        a.minCredit === this.getInterestLineByIndex(ilIndex).minCredit &&
        a.maxCredit === this.getInterestLineByIndex(ilIndex).maxCredit &&
        a.duration === duration &&
        a.ilIndex === ilIndex &&
        a.interestRate === value &&
        a.commissionInterest === true
      );
      // if (doublet != null && doublet.commissionInterest === true) {
      if (doublets != null) {
        for (let doublet of doublets) {
          att.commission = doublet.commission;
          // att.commission = att.commission.concat(doublet.commission);
          doublet.duration = -1; // so attribute can't be found anymore
          doublet.interestRate = null; // so attribute will be removed on save
        }
      } else {
        att.commission = [];
      }
      // überprüfung des gesicherten ConditionAttributes ob es zur Provisionsgruppe gehört
      // existieren Provisions-/Subventionseinträge
      if (saveAtt.commission != null && saveAtt.commission.length > 0) {
        // Überprüfen, dass es nicht nur ein Bestzins war
        let interestList = this.getCalcTypeInterestScale(this.condition.calcType);
        if (interestList.find((it) => it === saveAtt.interestRate)) {
          saveAtt.commissionInterest = true;
          this.condition.attributes.push(saveAtt);
        }
      }
      if (att.delete === true) {
        this.condition.attributes = this.condition.attributes.filter((a) => a.ilIndex !== ilIndex || a.duration !== duration);
      }
    },

    // --------------------------------------------
    copyAttributeValue (ilIndex, sourceDuration) {
      let nextDurationIndex = (this.durationList.findIndex((d) => d === sourceDuration)) + 1;
      if (nextDurationIndex < this.durationList.length) { // do only if there is next duration
        let sourceAtt = this.getConditionAttribute(
          this.getInterestLineByIndex(ilIndex).minCredit,
          this.getInterestLineByIndex(ilIndex).maxCredit,
          sourceDuration,
          ilIndex,
          false
        );

        let targetDuration = this.durationList[nextDurationIndex];
        let targetAtt = this.getConditionAttribute(
          this.getInterestLineByIndex(ilIndex).minCredit,
          this.getInterestLineByIndex(ilIndex).maxCredit,
          targetDuration,
          ilIndex,
          true,
          null,
          null,
          sourceAtt.interestTypeAttribute
        );
        // sichern des alten Wertes
        let saveAtt = JSON.parse(JSON.stringify(targetAtt));

        targetAtt.interestRate = sourceAtt.interestRate;
        targetAtt.plusFactor = sourceAtt.plusFactor;
        targetAtt.minusFactor = sourceAtt.minusFactor;
        targetAtt.interestTypeAttribute = sourceAtt.interestTypeAttribute;

        let doublet = this.condition.attributes.find((a) =>
          a.minCredit === this.getInterestLineByIndex(ilIndex).minCredit &&
          a.maxCredit === this.getInterestLineByIndex(ilIndex).maxCredit &&
          a.duration === targetDuration &&
          a.ilIndex === ilIndex &&
          a.interestRate === targetAtt.interestRate &&
          a.commissionInterest === true
        );
        // Wert der alten Provision wird beibehalten
        if (doublet != null) {
          targetAtt.commission = doublet.commission;
          doublet.duration = -1; // so attribute can't be found anymore
          doublet.interestRate = null; // so attribute will be removed on save
        }
        // Überprüfen, dass es nicht nur ein Bestzins war

        if (saveAtt.interestRate !== targetAtt.interestRate) {
          let interestList = this.getCalcTypeInterestScale(this.condition.calcType);
          if (interestList.find((it) => it === saveAtt.interestRate)) {
            saveAtt.commissionInterest = true;
            this.condition.attributes.push(saveAtt);
          }
        }
        this.setDirty();
      }
    },
    getInterestLineByIndex (ilIndex) {
      return this.condition.interestLines.find((i) => i.index === ilIndex);
    },
    copyInterestLine (ilIndex) {
      let sourceInterestLine = this.getInterestLineByIndex(ilIndex);
      let nl = JSON.parse(JSON.stringify(sourceInterestLine));
      nl.minCredit = sourceInterestLine.maxCredit + 0.01;
      nl.maxCredit = null;
      nl.index = this.interestLineCounter;
      this.allInterestTypeSet.push(this.allInterestTypeSet[ilIndex]);
      this.interestLineCounter++;

      let attributes = JSON.parse(JSON.stringify(this.condition.attributes.filter((a) => a.ilIndex === ilIndex)));
      for (let att of attributes) {
        att.ilIndex = nl.index;
        att.minCredit = nl.minCredit;
        att.maxCredit = nl.maxCredit;
        this.condition.attributes.push(att);
      }

      this.condition.interestLines.push(nl);
      this.setDirty();
    },
    addInterestLine () {
      this.createInterestLine(this.condition.calcType, this.condition.interestLines, this.interestLineCounter, null);
      this.allInterestTypeSet.push(false);
      this.interestLineCounter++;
      this.setDirty();
    },
    async removeInterestLine (ilIndex) {
      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.conditionEditor.dlghDeleteInterestLine'), this.$t('condAdmin.conditionEditor.dlgtDeleteInterestLine'))) {
        let index = this.condition.interestLines.findIndex((i) => i.index === ilIndex);
        if (index > -1) {
          this.condition.interestLines.splice(index, 1);
          this.allInterestTypeSet.splice(index, 1);
        }
        this.condition.attributes = this.condition.attributes.filter((a) => a.ilIndex !== ilIndex);
      }
      this.setDirty();
    },
    setInterestTypeAtttribute (index, duration = null) {
      if (duration != null) {
        let att = this.condition.attributes.find((a) =>
          a.minCredit === this.getInterestLineByIndex(index).minCredit &&
          a.maxCredit === this.getInterestLineByIndex(index).maxCredit &&
          a.duration === duration &&
          a.ilIndex === index &&
          !a.commissionInterest);
        if (att != null) {
          att.interestTypeAttribute = !att.interestTypeAttribute;
        }
        let atts = this.condition.attributes.filter((a) =>
          a.minCredit === this.getInterestLineByIndex(index).minCredit &&
          a.maxCredit === this.getInterestLineByIndex(index).maxCredit &&
          a.ilIndex === index &&
          !a.commissionInterest);

        if (atts != null) {
          this.allInterestTypeSet[index] = true;
          for (let att of atts) {
            if (!att.interestTypeAttribute) {
              this.allInterestTypeSet[index] = false;
            }
          }
        }
      } else {
        this.allInterestTypeSet[index] = !this.allInterestTypeSet[index];
        for (let duration in this.durationList) {
          let att = this.condition.attributes.find((a) =>
            a.minCredit === this.getInterestLineByIndex(index).minCredit &&
            a.maxCredit === this.getInterestLineByIndex(index).maxCredit &&
            a.duration === this.durationList[duration] &&
            a.ilIndex === index &&
            !a.commissionInterest);
          if (att != null) {
            att.interestTypeAttribute = this.allInterestTypeSet[index];
          }
        }
      }
      this.setDirty();
    },

    getInterestTypeAttribute (index, duration = null) {
      if (duration != null) {
        let att = this.condition.attributes.find((a) =>
          a.minCredit === this.getInterestLineByIndex(index).minCredit &&
          a.maxCredit === this.getInterestLineByIndex(index).maxCredit &&
          a.duration === duration &&
          a.ilIndex === index &&
          !a.commissionInterest);
        return att.interestTypeAttribute;
      } else {
        return this.allInterestTypeSet[index];
      }
    },

    /* *******************
    *
    * ResidualValues
    *
    ******************* */
    createResidualValues () {
      let rv = [];
      for (let d of this.durationList) {
        rv.push({
          duration: d,
          residualValue: null
        });
      }
      this.residualValues = rv;
      this.condition.additionalParams.residualValues = this.residualValues;
      this.condition.additionalParams.rvMaxLowering = 0;
      this.condition.additionalParams.rvMaxRaise = 0;
      this.setDirty();
    },
    removeResidualValues () {
      this.residualValues = null;
      delete this.condition.additionalParams.residualValues;
      delete this.condition.additionalParams.rvMaxLowering;
      delete this.condition.additionalParams.rvMaxRaise;
      this.setDirty();
    },
    setResidualValue (d, rv) {
      let e = this.residualValues.find((r) => r.duration === d);
      if (e != null) {
        e.residualValue = rv;
      } else {
        this.residualValues.push({ duration: d, residualValue: rv });
      }
      this.setDirty();
    },
    getResidualValue (d) {
      let e = this.residualValues.find((r) => r.duration === d);
      if (e != null) return e.residualValue;
      return null;
    },
    /* *******************
    *
    * commissionParams
    *
    ******************* */
    changeCommissionMode (comTable, mode, name) {
      comTable.mode = mode;
      comTable.name = name.trim();
      let commissionType = this.commissionTypes.find((c) => c.value === name);
      comTable.isSubsidy = commissionType.isSubsidy;

      // let attributes = this.condition.attributes.filter((a) => a.commissionInterest === true);
      for (let att of this.condition.attributes) {
        let com = this.getAttributeCommission(att, comTable);
        if (com != null) {
          com.mode = mode;
          com.name = name;
        }
      }
      this.setDirty();
    },
    /*
    addNewCommission () {
      let ct = this.createNewCommission(this.condition.commissionParams, null);
      ct.mode = this.commissionModes[0].value;
      ct.expand = true;
      this.setDirty();
    },
    removeCommission (uid) {
      let index = this.condition.commissionParams.findIndex((c) => c.uid === uid);
      if (index > -1) {
        this.condition.commissionParams.splice(index, 1);
      }
      let attributes = this.condition.attributes.filter((a) => a.commissionInterest === true);
      for (let att of attributes) {
        att.commission = att.commission.filter((c) => c.uid !== uid);
        if (att.commission.length === 0) {
          att.delete = true;
        }
      }
      this.condition.attributes = this.condition.attributes.filter((a) => a.delete !== true);
      this.setDirty();
    }, */
    copyCommissionAttributes (sourceILine, comTable) {
      console.log('copy commissions');
      // get position of current interestLine (source)
      let sourceIlPos = this.condition.interestLines.findIndex((e) => e.index === sourceILine.index);
      if (sourceIlPos != null && sourceIlPos < this.condition.interestLines.length - 1) {
        // get index of following interestLine (target)
        let sourceIl = this.condition.interestLines[sourceIlPos];
        let targetIl = this.condition.interestLines[sourceIlPos + 1];
        console.log('targetIlIndex', targetIl.index);

        // copy all comTable-attributes from source to target interestLine
        for (let duration of this.durationList) {
          for (let interest of this.interestScaleList) {
            let commValue = this.getCommissionValue(comTable, sourceIl.index, duration, interest, sourceIl.minCredit, sourceIl.maxCredit);
            this.setCommissionValue(comTable, targetIl.index, duration, interest, targetIl.minCredit, targetIl.maxCredit, commValue);
          }
          let commValue = this.getCommissionValue(comTable, sourceIl.index, duration, null, sourceIl.minCredit, sourceIl.maxCredit);
          this.setCommissionValue(comTable, targetIl.index, duration, null, targetIl.minCredit, targetIl.maxCredit, commValue);
        }
      }
    },
    getAttributeCommission (att, comTable) {
      // for (let c of att.commission) {
      //   if (c.uid === comTable.uid) return c;
      // }
      // return null;
      return att.commission.find((c) => c.uid === comTable.uid);
    },
    getCommissionValue (comTable, ilIndex, duration, interest, minCredit, maxCredit) {
      let att = this.getConditionAttribute(minCredit, maxCredit, duration, ilIndex, false, interest);
      let com = this.getAttributeCommission(att, comTable);
      if (com == null) return null;
      return com.value;
    },
    setCommissionValue (comTable, ilIndex, duration, interest, minCredit, maxCredit, value) {
      let att = this.getConditionAttribute(minCredit, maxCredit, duration, ilIndex, true, interest);
      let com = this.getAttributeCommission(att, comTable);
      if (com == null && value != null) {
        com = {
          name: comTable.name,
          mode: comTable.mode,
          uid: comTable.uid,
          value: value
        };
        att.commission.push(com);
      } else if (com != null) {
        com.value = value;
        if (value == null) {
          att.commission = att.commission.filter((e) => e.value != null);
        }
      }
      this.setDirty();
    },
    getCalcTypeInterestScale (calcType) {
      let ct = this.$store.state.condAdmin.calcTypeList.find((i) => i.calcType === calcType);
      return ct.settings.interestScale.sort((a, b) => { return a - b; });
    },
    /* *******************
    *
    * DealerAssignment
    *
    ******************* */
    addDealerAssignment () {
      this.condition.dealers.push({
        isInclude: 1,
        id: null,
        dealerKey: null,
        name: null,
        city: null
      });
      this.setDirty();
    },
    copyDealerAssignment (item) {
      this.condition.dealers.push(JSON.parse(JSON.stringify(item)));
      this.setDirty();
    },
    setDealerAssignment (item, dealerId) {
      if (dealerId == null) {
        item.id = null;
        // item.dealerKey = dealer.dealerKey;
        item.name = null;
        item.city = null;
      } else {
        let dealer = this.$store.state.condAdmin.dealerList.find((d) => d.id === dealerId);
        item.id = dealerId;
        // item.dealerKey = dealer.dealerKey;
        item.name = dealer.name;
        item.city = dealer.city;
      }
      this.setDirty();
    },
    removeDealerAssignment (item) {
      let index = this.condition.dealers.findIndex((d) => d.isInclude === item.isInclude && d.id === item.id);
      if (index > -1) {
        this.condition.dealers.splice(index, 1);
      }
    },
    /* *******************
    *
    * save
    *
    ******************* */
    async save () {
      if (this.$store.state.condAdmin.orgSettings.useInterestScale === true) {
        this.$refs.CommissionEditor.getCommisionData(this.condition);
      }
      if (this.customComponents.ConditionKeyID != null && this.condition.conditionKeyFix === false) {
        this.condition.conditionKeyParams = this.$refs.customConditionId.getConditionsKeyData();
      }
      console.debug('save condition', JSON.stringify(this.condition.conditionKeyParams));
      let processData = await this.saveCondition(this.condition);
      if (processData != null) {
        this.$globals.Info.open([{ type: 'success', message: this.$t('condAdmin.common.infoDraftSaved') }]);
        this.isDirty = false;
        // update conditionList
        this.refreshConditionList();
        this.$store.state.condAdmin.workingCondition = null;

        this.locked = false;
        this.$router.push('/condAdmin/condition/' + this.condition.uid + '/' + this.condition.version);
      }
    }
  }
};
</script>

<style scoped>
.conditionTable,
.comTable {
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 150px;
  padding: 0;
}

.conditionTable table,
.comTable table {
  border-collapse: separate;
  border-spacing: 0;
}

.conditionTable td:not(.smallRow),
.conditionTable th:not(.smallRow),
.comTable td,
.comTable th,
.greyRow:not(.smallRow) {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  white-space: nowrap;
  min-width: 150px;
}

.conditionTable .smallRow {
  width: 75px !important;
}

.conditionTable td.first,
.conditionTable th.first,
.comTable td.first,
.comTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.conditionTable td.noBorder,
.comTable td.noBorder {
  border: 0px !important;
}

.comTable td,
.comTable th {
  height: 36px !important;
}

.conditionTable .creditCol {
  height: 104px !important;
}

.conditionTable .fixCol {
  position: absolute !important;
  width: 150px;
  left: 32px;
  top: auto;
}

.comTable .fixCol {
  position: absolute !important;
  width: 150px;
  left: 64px;
  top: auto;
}

.conditionTable .interestField.v-input,
.comTable .v-input {
  margin-top: -12px !important;
}

.interestField {
  width: 150px !important;
  max-width: 150px !important;
}

.creditField {
  width: 175px !important;
  max-width: 175px !important;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.lastRow {
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  /*padding-bottom: 8px !important;*/
}

.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
  color: var(--v-grey-darken1) !important;
}

.tfexpand>>>button {
  color: var(--v-secondary-base) !important;
}

.tfexpand>>>.v-input__icon--prepend {
  margin-left: 8px !important;
  margin-right: 16px !important;
}

.subsidyField>>>.v-text-field__slot,
.subsidyField>>>.v-select__selection,
.subsidyField>>>input {
  color: var(--v-error-base) !important;
}

.subsidyFieldDisabled>>>.v-text-field__slot,
.subsidyFieldDisabled>>>input {
  color: #8f8a8a !important;
}
</style>
