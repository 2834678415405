<template>
  <v-card
    class="minContent"
    v-if="filteredList != null"
  >

    <v-card-title>
      <v-menu
        offset-y
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="selected.length === 0"
            color="secondary"
          >
            <v-icon class="mr-2">mdi-menu-down</v-icon>
            {{ $t("base.actions") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="deleteRvTables()"
            v-if="getOrgSetting('createOwnData') === true"
          >
            <v-icon class="mr-4">mdi-delete-forever</v-icon>
            {{ $t("base.delete")}}
          </v-list-item>
          <v-list-item @click="exportData()">
            <v-icon class="mr-4">mdi-export</v-icon>
            {{ capitalizeString('base.export') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="ml-8 body-1">
        {{ selected.length + " " + $t('base.of') + " " + filteredList.length + " " + $t('base.selected') }}
      </span>
      <v-spacer />
      <QuickFilter
        v-if="hasInheritedTables"
        class="mr-2"
        :label="$t('condAdmin.filter.inherited')"
        :count="inheritedCount"
        :active="showInherited === true"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <QuickFilter
        v-if="hasInheritedTables"
        :label="$t('condAdmin.filter.own')"
        :count="filteredList.length - inheritedCount"
        :active="showInherited === false"
        :selectValue="false"
        :deselectValue="null"
        @clicked="applyInheritedFilter"
      />
      <v-btn
        @click="createRvTable()"
        color="secondary"
        class="mx-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.newResidualValue'))"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t("base.new") }}
      </v-btn>
      <v-icon
        @click="loadRVTable()"
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.refresh'))"
      >mdi-reload</v-icon>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
            v-tooltip="$tooltip($t('condAdmin.tooltip.menu'))"
          >mdi-format-columns</v-icon>
        </template>

        <v-list>
          <v-list-item
            v-for="(col, index) in availableColumns"
            :key="index"
          >
            <v-checkbox
              v-model="availableColumns[index].active"
              :label="$t(availableColumns[index].text)"
              @change="setAvailableHeaders(availableColumns[index],$event)"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        @click="saveUserTableSettings('residualValueTable')"
        color="secondary"
        class="ml-8"
        v-tooltip="$tooltip($t('condAdmin.tooltip.save'))"
      >mdi-content-save-cog</v-icon>
      <JsonImporter
        v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
        :show=true
        class="ml-8"
        objectType="rvTables"
        module="condAdmin"
        :preImportCheck="preImportCheckFunction"
        @import="importData"
      />
    </v-card-title>

    <v-card-text class="pt-4">
      <v-data-table
        v-model="selected"
        :headers="listHeaders"
        :items="filteredList"
        :items-per-page="footerProps.itemsPerPage"
        :footer-props="footerProps"
        item-key="feid"
        show-select
        fixed-header
        :sort-by="listSortKey"
        @click:row="clickedRow"
        :loading="$store.state.condAdmin.rvTablesLoading"
        @update:items-per-page="getItemPerPage"
      >
        <template
          v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`body.prepend`]="{}">
          <tr>
            <td></td>
            <td
              v-for="(h, index) in listHeaders"
              :key="index"
            >
              <SearchField
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchField'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
              >
              </SearchField>
              <SearchSelect
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SelectBox'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
                :items="stateList"
                :item-text="filter[h.value].itemText"
                :item-value="filter[h.value].itemValue"
              />
              <SearchDatePicker
                v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'DatePicker'"
                :value="filter[h.value].value"
                @filter="applyFilters($event, h.value)"
              />
            </td>
          </tr>
        </template>
        <template v-slot:[`header.data-table-select`]="{ on, props }">
          <v-simple-checkbox
            color="secondary"
            v-bind="props"
            v-on="on"
          ></v-simple-checkbox>
        </template>
        <template v-slot:[`header.menu`]="{}">
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <v-chip :class="getStateClass(item)">
            {{ getStateText(item) }}
            <v-icon
              class="ml-2"
              v-if="item.inherited === true"
              v-tooltip="$tooltip($t('condAdmin.rvTable.inheritedRVTable'))"
            >{{ getOrgSetting('createOwnData') === true ? 'mdi-link' : 'mdi-link-lock' }}</v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.menu`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!(enableAction('edit', item))"
                @click="editRvTable('edit', item)"
              >{{ capitalizeString('condAdmin.actions.edit') }}
              </v-list-item>
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true"
                :disabled="!(enableAction('copy', item))"
                @click="editRvTable('copy', item) && getOrgSetting('createOwnData') === true"
              >{{ capitalizeString('condAdmin.actions.copy') }}
              </v-list-item>
              <!-- <v-list-item
                    v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                    :disabled="!enableAction('end', item)"
                    @click="end(item)"
                  >{{ capitalizeString('condAdmin.actions.end') }}
                  </v-list-item>-->
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('remove', item)"
                @click="removeDraft(item)"
              >{{ capitalizeString('base.delete')}}
              </v-list-item>
              <v-list-item
                v-if="hasUserRight('manageResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('setCheck', item)"
                @click="setRVTableProductState(2, item)"
              >{{ hasExamination() ? capitalizeString('condAdmin.actions.setCheck') : capitalizeString('condAdmin.actions.setChecked') }}

              </v-list-item>
              <v-list-item
                v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('setChecked', item)"
                @click="setRVTableProductState(4, item)"
              >{{ capitalizeString('condAdmin.actions.setChecked') }}
              </v-list-item>
              <v-list-item
                v-if="hasExamination() && hasUserRight('examineResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('setRework', item)"
                @click="setRVTableProductState(1, item)"
              >{{ capitalizeString('condAdmin.actions.setRework') }}
              </v-list-item>
              <v-list-item
                v-if="hasActivatorRole() && hasUserRight('activateResidualValues') && getOrgSetting('createOwnData') === true && !item.inherited"
                :disabled="!enableAction('enable', item)"
                @click="setRVTableProductState(6, item)"
              >{{ capitalizeString('condAdmin.actions.enable') }}
              </v-list-item>
              <v-list-item
                v-if="item.inherited !== true"
                @click="showHistory('getRvTableHistory', item.rvTableKey, item.name)"
              >{{ $t('condAdmin.actions.history') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <HistoryDlg ref="historyDlg" />
    <JsonExporter
      objectType="rvTables"
      module="condAdmin"
      ref="jsonExporter"
    />
    <ResidualValuesTableImportDlg ref="residualValuesTableImportDlg" />
  </v-card>
</template>

<script lang="js">
import condAdminMixin from '../js/condAdminMixin.js';
import condAdminResidualValuesMixin from '../js/condAdminResidualValuesMixin.js';
import condAdminRvGroupMixin from '../js/condAdminRvGroupMixin.js';
import { complexFilter } from '@/base/js/ObjectHelper';
import SearchField from '@/common/SearchField';
import SearchSelect from '@/common/SearchSelect';
import SearchDatePicker from '@/common/SearchDatePicker';
import QuickFilter from '@/common/QuickFilter';
import HistoryDlg from './HistoryDlg';
import JsonImporter from '@/common/JsonImporter';
import JsonExporter from '@/common/JsonExporter';
import { newMessage } from '@/base/js/ProcessDataHelper';
import ResidualValuesTableImportDlg from './ResidualValuesTableImportDlg';

export default {
  name: 'ResidualValuesTable',
  components: {
    SearchField,
    SearchSelect,
    SearchDatePicker,
    QuickFilter,
    HistoryDlg,
    JsonImporter,
    JsonExporter,
    ResidualValuesTableImportDlg
  },
  mixins: [ condAdminResidualValuesMixin, condAdminMixin, condAdminRvGroupMixin ],
  data () {
    return {

      selected: [],
      expanded: [],
      listSortKey: 'rvTableKey',
      filteredList: [],
      availableColumns: [
        { text: this.$t('condAdmin.rvTable.rvTableKey'), active: true, value: 'name' },
        { text: this.$t('condAdmin.version'), active: true, value: 'version' },
        { text: this.$t('condAdmin.validFrom'), active: true, value: 'validFrom', formatter: this.formatBeginDate },
        { text: this.$t('condAdmin.validTo'), active: true, value: 'validTo', formatter: this.formatEndDate },
        { text: this.$t('base.state'), active: true, value: 'state' }
      ],
      showInherited: null,
      filter: {
        name: { condition: 'startsWith', value: null, type: 'SearchField' },
        validFrom: { condition: 'date.<=', value: null, type: 'DatePicker' },
        validTo: { condition: 'date.>=', value: null, type: 'DatePicker' },
        state: { condition: '=', value: null, type: 'SelectBox', items: 'stateList', itemText: 'text', itemValue: 'state' }
      },

      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1],
        itemsPerPage: 15
      }
    };
  },
  computed: {
    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    },
    stateList () {
      let list = [];
      for (let c of this.$store.state.condAdmin.rvTableList) {
        if (list.length === 0) {
          list.push({ state: c.state, text: this.$t('condAdmin.state.' + this.states[c.state]) });
        } else {
          if (list.find((i) => i.value === c.state) == null) {
            list.push({ state: c.state, text: this.$t('condAdmin.state.' + this.states[c.state]) });
          }
        }
      }
      return list.sort();
    },
    hasInheritedTables () {
      return (this.$store.state.condAdmin.rvTableList.filter((c) => c.inherited === true).length > 0);
    },
    inheritedCount () {
      return this.filteredList.filter((e) => e.inherited === true).length;
    }
  },
  watch: {
    '$store.state.condAdmin.rvTableList': function () {
      this.filterTables();
    }
  },
  mounted () {
    this.getUserTableSettings('residualValueTable');
    this.filterTables();
    this.$emit('setPageMode', 'table');
  },
  methods: {
    filterTables () {
      let list = this.$store.state.condAdmin.rvTableList;
      if (this.showInherited === true) {
        list = list.filter((e) => e.inherited === true);
      } else if (this.showInherited === false) {
        list = list.filter((e) => e.inherited !== true);
      }
      this.filteredList = complexFilter(list, this.filter);
    },
    applyInheritedFilter (value) {
      this.showInherited = value;
      console.log('inheritedFilter', this.showInherited);
      this.filterTables();
    },
    async loadRVTable (forced = true) {
      if (forced || this.$store.state.condAdmin.rvTableList == null || this.$store.state.condAdmin.rvTableList.length === 0) {
        await this.refreshRvTableList();
        this.filterTables();
      }
    },
    async deleteRvTables () {
      // bereits einer Gruppe zugeordneten Restwerte dürfen nicht gelöscht werden
      let doNotdeleteMessage = null;
      let doDeleteRV = [];
      if (this.$store.state.condAdmin.rvGroupList == null || this.$store.state.condAdmin.rvGroupList.length === 0) {
        await this.refreshRvGroupList();
      }

      if (await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.rvTableList.deleteRvTable'), this.$t('condAdmin.rvTableList.deleteRvTablesConfirmText'))) {
        for (let rvTable of this.selected) {
          // Überprüfen, ob es zu dem Draft bereits eine aktive Version gibt
          let aktivVersion = this.$store.state.condAdmin.rvTableList.find(rvt => rvt.rvTableKey === rvTable.rvTableKey && rvt.state === 6);
          // gibt es keine aktive Version, dann erstmal nicht löschen, sondern überprüfen ob der Entwurf schon Objekten zugewiesen ist
          if (aktivVersion == null) {
            let temp = this.$store.state.condAdmin.rvGroupList.find(rvg => rvg.finRVTableKey === rvTable.rvTableKey || rvg.leasRVTableKey === rvTable.rvTableKey);
            if (temp != null) {
              if (doNotdeleteMessage === null) {
                doNotdeleteMessage = this.$t('condAdmin.rvTableList.avoidRvTableDeleteText', { rvTableName: rvTable.name, rvGroupListName: temp.name }) + '\n';
              } else {
                doNotdeleteMessage += this.$t('condAdmin.rvTableList.avoidRvTableDeleteText', { rvTableName: rvTable.name, rvGroupListName: temp.name }) + '\n';
              }
            } else {
              doDeleteRV.push(rvTable);
              // await this.deleteRvTable(rvTable, true);
            }
          } else {
            // await this.deleteRvTable(rvTable, true);
            doDeleteRV.push(rvTable);
          }
        }
      }
      this.selected = [];
      if (doNotdeleteMessage != null) {
        await this.$globals.Confirm.okDlg(this.$t('condAdmin.rvTableList.avoidRvTableDelete'), doNotdeleteMessage);
      }
      for (let delTable of doDeleteRV) {
        await this.deleteRvTable(delTable, true);
      }

      await this.refreshRvTableList();
    },

    async removeDraft (rvTable) {
      if (this.$store.state.condAdmin.rvGroupList == null || this.$store.state.condAdmin.rvGroupList.length === 0) {
        await this.refreshRvGroupList();
      }
      // Überprüfen, ob es zu dem Draft bereits eine aktive Version gibt
      let aktivVersion = this.$store.state.condAdmin.rvTableList.find(rvt => rvt.rvTableKey === rvTable.rvTableKey && rvt.state === 6);
      // gibt es keine aktive Version, dann erstmal nicht löschen, sondern überprüfen ob der Entwurf schon Objekten zugewiesen ist
      if (aktivVersion == null) {
        let temp = this.$store.state.condAdmin.rvGroupList.find(rvg => rvg.finRVTableKey === rvTable.rvTableKey || rvg.leasRVTableKey === rvTable.rvTableKey);
        if (temp != null) {
          await this.$globals.Confirm.okDlg(this.$t('condAdmin.rvTableList.avoidRvTableDelete'), this.$t('condAdmin.rvTableList.avoidRvTableDeleteText', { rvTableName: rvTable.name, rvGroupListName: temp.name }));
        } else {
          if (await this.deleteRvTable(rvTable) === true) {
            await this.refreshRvTableList();
          }
        }
      } else {
        if (await this.deleteRvTable(rvTable) === true) {
          await this.refreshRvTableList();
        }
      }
    },

    async createRvTable () {
      if (await this.checkEditPreResidualValuesTable('new') === true) {
        this.$router.push('/condAdmin/residualValues/edit');
      } else {
        this.$logger.debug('condAdmin', 'ResidualValuesTable.vue: edit else !!!');
      }
    },
    async editRvTable (mode, rvTable = null) {
      if (rvTable.versions == null) {
        rvTable = await this.readRvTableData(rvTable.rvTableKey, rvTable.version);
      }
      if (await this.checkEditPreResidualValuesTable(mode, rvTable) === true) {
        this.$router.push('/condAdmin/residualValues/edit');
      } else {
        this.$logger.debug('condAdmin', 'ResidualValuesTable.vue: edit else !!!');
      }
    },
    clickedRow (rvTable) {
      this.viewRVTable(rvTable);
    },
    viewRVTable (rvTable = null) {
      this.$router.push('/condAdmin/residualValues/' + rvTable.rvTableKey + '/' + rvTable.version);
    },
    enableAction (action, rvTable) {
      switch (action) {
        case 'edit':
        case 'copy': return true;
        case 'end': return false;
        case 'remove': return rvTable.inherited !== true && rvTable.state < 6;
        case 'setCheck': return rvTable.inherited !== true && (rvTable.state === 0 || rvTable.state === 1);
        case 'setChecked':return rvTable.inherited !== true && (rvTable.state === 2 || rvTable.state === 4);
        case 'setRework': return rvTable.inherited !== true && (rvTable.state === 2 || rvTable.state === 4);
        case 'enable': return rvTable.inherited !== true && (rvTable.state === 5);
      }
      return true;
    },
    async setRVTableProductState (state, rvTable) {
      if (state === 2 || state === 4 || state === 5) {
        // check plausibilities
        if (rvTable.versions == null) {
          rvTable = await this.readRvTableData(rvTable.rvTableKey, rvTable.version);
        }
        let beginDateCheck = await this.validateBeginDate(rvTable);
        let endDateCheck = await this.validateEndDate(rvTable);
        if (!beginDateCheck || !endDateCheck) {
          return false;
        }
      }
      await this.setState(rvTable, state);
      if (rvTable.versions != null) {
        let v = rvTable.versions.find((c) => c.version === rvTable.version);
        v.state = rvTable.state;
      }
      await this.loadRVTable();
    },
    getItemPerPage (val) {
      this.footerProps.itemsPerPage = val;
      this.$store.state.condAdmin.userSettings['residualValueTable'].footerPropItemsPerPage = val;
    },
    setAvailableHeaders (column, value) {
      this.$store.state.condAdmin.userSettings['residualValueTable'].columns[column.value] = value;
    },
    applyFilters (value, filtername) {
      this.filter[filtername].value = value;
      this.filterTables();
      this.$store.state.condAdmin.userSettings['residualValueTable'].filter[filtername] = value;
    },
    // -------------------- Export / Import ---------------------------
    async exportData () {
      let residualValeTableList = [];
      for (let c of this.selected) {
        let rvTable = JSON.parse(JSON.stringify(await this.readRvTableData(c.rvTableKey, c.version)));

        delete rvTable.versions;
        delete rvTable.id;
        delete rvTable.clientKey;
        delete rvTable.timestamp;
        delete rvTable.feid;

        residualValeTableList.push(rvTable);
      }
      this.$refs.jsonExporter.exportJsonData({ residualValeTableList });
    },
    async importData (json) {
      await this.refreshRvTableList();
      let imported = [];
      let total = json.data.residualValeTableList.length;

      for (let imp of json.data.residualValeTableList) {
        let action = await this.$refs.residualValuesTableImportDlg.show(imp);
        switch (action) {
          case 'startImport':
            let processData = await this.saveResidualValues(imp, false);
            if (processData != null) {
              this.$store.state.condAdmin.rvTableList.push(processData.ioData.rvTable);
              imported.push({ rvTableKey: processData.ioData.rvTable.rvTableKey, version: processData.ioData.rvTable.version });
            }
            break;
          case 'skip':
          default:
            break;
        }
      }

      this.$globals.Info.open([ newMessage('success', this.$t('condAdmin.importResult', { imported, total })) ]);
      if (imported.length > 0) {
        let activate = await this.$globals.Confirm.yesNoDlg(this.$t('condAdmin.rvGroupImport.dlghActivateImports'), this.$t('condAdmin.rvGroupImport.dlgtActivateImports'), this.$t('condAdmin.actions.setChecked'), this.$t('condAdmin.actions.keepDraft'));
        if (activate === true) { // activate conditions
          for (let r of imported) {
            this.setState(r, 6);
          }
        }
        this.loadRVTable(true);
      }
    },
    preImportCheckFunction (json) {
      if (json.data.residualValeTableList == null || json.data.residualValeTableList.length < 1) {
        this.$globals.Info.open([ newMessage('userError', this.$t('condAdmin.rvTableImport.noImportData')) ]);
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
</style>
